import React from 'react';
import MainLayout from '../../layout/MainLayout';
import StepSixHeader from '../../components/AssessmentsAndTests/StepSix/StepSixHeader';
import StepSixContent from '../../components/AssessmentsAndTests/StepSix/StepSixContent';

const AssessmentResult = () => {
  const resultId = new URLSearchParams(window.location.search).get('resultId');
  const retakeUrl: any = new URLSearchParams(window.location.search).get(
    'retakeUrl'
  );
  const retake = () => {
    (window as Window).location = retakeUrl || '/';
  };
  return (
    <MainLayout
      headerProgress={100}
      headerContent={<StepSixHeader retake={retake} />}
    >
      <StepSixContent id={resultId || ''} />
    </MainLayout>
  );
};

export default AssessmentResult;
