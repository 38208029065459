import logo from '../../assets/logo.png';
const Connect = () => {

    return (
        <section className="bg-[#1A1A1A] md:py-[60px] py-[40px]">
            <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                <div className="lg:flex items-center gap-[57px]">
                    <div className="lg:mb-0 mb-4">
                        <img className="lg:h-[100px] md:h-[80px] h-[60px]" src={logo} alt="" />
                    </div>
                    <div>
                        <span className="bg-[#333333] rounded py-1 px-2 text-sm text-white inline-block mb-3">Select, Connect, and Hire</span>
                        <h3 className="lg:text-[41px] md:text-[30px] text-[20px] font-semibold text-white mb-4">Be Part of the Hiring Tech Revolution</h3>
                        {/* <p className="text-xs text-[#7E7E81]">Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Connect;