
const Hiring = () => {

    return (
        <section className="bg-[#1A1A1A] md:py-[60px] py-[40px]">
            <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                <div className="lg:flex items-center justify-between">
                    <div className="lg:mb-0 mb-4">
                        <span className="bg-[#333333] rounded py-1 px-2 text-sm text-white inline-block mb-3">A Hiring Treasure Trove</span>
                        <h3 className="lg:text-[41px] md:text-[30px] text-[20px] font-semibold text-white">Step into Success: <br />
                        Explore the Hiring experience with us!</h3>
                    </div>
                    <a href="/book-a-demo" className="inline-block px-4 py-2.5 bg-[#F8FA1F] rounded-lg">Book a Demo</a>
                </div>
            </div>
        </section>
    )
}

export default Hiring;