import React from "react";
import MainContent from "../../components/Dashboard/MainContent";
import DashboardLayout from "../../layout/DashboardLayout";

const Dashboard = () => {
  return (
    <DashboardLayout>
      <MainContent />
    </DashboardLayout>
  );
};

export default Dashboard;
