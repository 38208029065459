import socialIcon1 from '../../assets/new-social-icon/1.svg';
import socialIcon2 from '../../assets/new-social-icon/2.svg';
import socialIcon3 from '../../assets/new-social-icon/3.svg';
const Footer = () => {

    return (
        <footer className="bg-[#141414]">
            <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                <div className="flex flex-wrap lg:gap-[57px] md:gap-[40px] gap-[20px] lg:py-[57px] md:py-[40px] py-[20px] border-b border-[#262626]">
                    <div className="lg:w-2/5 w-full lg:mb-0 mb-8">
                        <h4 className="text-white md:text-3xl sm:text-2xl text-xl font-medium lg:mb-8 sm:mb-6 mb-4">
                            Get in Touch
                        </h4>
                        <ul>
                            <li className="md:mb-4 sm:mb-3 mb-2">
                                <a
                                    href="mailto:sales@learningbooth.co"
                                    className="text-light-white lg:text-2xl sm:text-xl text-base"
                                >
                                    sales@learningbooth.co
                                </a>
                            </li>
                            <li>
                                <a
                                    href="callto:+919147064666"
                                    className="text-light-white lg:text-2xl sm:text-xl text-base"
                                >
                                    +91 9147064666, +91 8217713256
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="lg:w-2/12">
                        <div className="flex flex-col gap-6">
                            <h4 className="text-sm font-semibold text-white">News</h4>
                            <ul>
                                <li className="md:pb-6 sm:pb-4 pb-2">
                                    <a href="/dashboard" className="text-[#666666] inline-block text-xs">
                                    Assessment
                                    </a>
                                </li>
                                <li className="md:pb-6 sm:pb-4 pb-2">
                                    <a href="/dashboard" className="text-[#666666] inline-block text-xs">
                                    Courses
                                    </a>
                                </li>
                                <li className="">
                                    <a href="/dashboard" className="text-[#666666] inline-block text-xs">
                                    Jobs
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="lg:w-2/12">
                        <div className="flex flex-col gap-6">
                            <h4 className="text-sm font-semibold text-white">News</h4>
                            <ul>
                                <li className="mb-3"><a href="#" className="text-[#666666] inline-block text-xs">Trending Stories</a></li>
                                <li className="mb-3"><a href="#" className="text-[#666666] inline-block text-xs">Technology</a></li>
                                <li className="mb-3"><a href="#" className="text-[#666666] inline-block text-xs">Politics</a></li>
                                <li className="mb-3"><a href="#" className="text-[#666666] inline-block text-xs">Environment</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
                <div className="md:flex md:justify-between items-center lg:py-7 py-4">
                    <ul className="flex justify-center items-center md:mb-0 mb-[10px]">
                        <li><a className="text-[#666666] text-xs inline-block px-3 border-r border-[#262626]" href="#">Terms & Conditions</a></li>
                        <li><a className="text-[#666666] text-xs inline-block px-3" href="#">Privacy Policy</a></li>
                    </ul>
                    <ul className="flex justify-center items-center gap-[10px]  md:mb-0 mb-[10px]">
                        <li><a className="text-[#666666] text-xs inline-block" href="#"><img src={socialIcon1} alt="" /></a></li>
                        <li><a className="text-[#666666] text-xs inline-block" href="#"><img src={socialIcon2} alt="" /></a></li>
                        <li><a className="text-[#666666] text-xs inline-block" href="#"><img src={socialIcon3} alt="" /></a></li>
                    </ul>
                    <p className="text-[#666666] text-xs text-center">© 2024 Third Bracket. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;