import React, { useState } from "react";
import DashboardLayout from "../../layout/DashboardLayout";
import StepOne from "../../components/AssessmentsForm/StepOne";
import StepTwo from "../../components/AssessmentsForm/StepTwo";

const AssessmentsForm = () => {
  const [step, setStep] = useState(0);
  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  return (
    <DashboardLayout>
      {(() => {
        console.log(step);
        
        switch (step) {
          case 0:
            return <StepOne prevStep={prevStep} nextStep={nextStep} />;
          case 1:
            return <StepTwo prevStep={prevStep} />;

          default:
            break;
        }
      })()}
    </DashboardLayout>
  );
};

export default AssessmentsForm;
