import React from "react";
import Header from "../components/Dashboard-new/Header";
import Sidebar from "../components/Dashboard-new/Sidebar";
// import Header from "../components/Dashboard/Header";
// import Sidebar from "../components/Dashboard/Sidebar";

const DashboardLayout = ({ children }: any) => {
  return (
    <>
      <Header />
      <Sidebar />
      {children}
    </>
  );
};

export default DashboardLayout;
