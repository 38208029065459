
import starIcon from '../../assets/star-icons.svg';
import star from '../../assets/star.svg';
import sliderImg from '../../assets/slider-img.png';
import bannerTop1 from '../../assets/banner-top-1.jpeg';
import bannerTop2 from '../../assets/banner-top-2.jpeg';
import bannerTop3 from '../../assets/banner-top-3.jpeg';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay } from 'swiper/modules';
const Briefinfo = () => {

    return (
        <section className="bg-[#1A1A1A]">
            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                autoHeight={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay]}
            >
                <SwiperSlide>
                    <div className="relative">
                        <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                            <div className="flex flex-wrap">
                                <div className="lg:w-7/12 w-full">
                                    <div className="flex flex-col gap-2.5 py-[20px]">
                                        <div>
                                            <img className="h-5" src={starIcon} alt="" />
                                        </div>
                                        <div>
                                            <span className="p-2 inline-block bg-[#333333] text-[#fff] rounded">Detailed evaluation and accurate matching of employee skills with job roles.</span>
                                        </div>
                                        <h3 className="lg:text-[20px] md:text-[18px] text-[16px] text-white font-medium">Ensure precise alignment of skills with job requirements, enhancing role fitment, minimizing training costs, and boosting productivity by placing the right talent in the right positions.</h3>
                                        <div>
                                            <a href="#" className="py-3 px-4 border border-[#454545] text-[#98989A] inline-flex gap-2 rounded">Try Now <img src={star} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap lg:absolute top-0 left-0 w-full h-full justify-end">
                            <div className="lg:w-5/12 w-full">
                                <img className="w-full" src={sliderImg} alt="" />
                            </div>
                        </div>
                    </div>
                    
                </SwiperSlide>
                <SwiperSlide>
                    <div className="relative">
                        <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                            <div className="flex flex-wrap">
                                <div className="lg:w-7/12 w-full">
                                    <div className="flex flex-col gap-2.5 py-[20px]">
                                        <div>
                                            <img className="h-5" src={starIcon} alt="" />
                                        </div>
                                        <div>
                                            <span className="p-2 inline-block bg-[#333333] text-[#fff] rounded">Assess cultural alignment and improve candidate experience.</span>
                                        </div>
                                        <h3 className="lg:text-[20px] md:text-[18px] text-[16px] text-white font-medium">Improve team cohesion and reduce turnover by ensuring candidates align with company values and culture. Increase offer acceptance rates and attract top talent through a seamless and engaging hiring process.</h3>
                                        <div>
                                            <a href="#" className="py-3 px-4 border border-[#454545] text-[#98989A] inline-flex gap-2 rounded">Try Now <img src={star} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap lg:absolute top-0 left-0 w-full h-full justify-end">
                            <div className="lg:w-5/12 w-full">
                                <img className="w-full" src={bannerTop1} alt="" style={{ height: '300px' }}/>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="relative">
                        <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                            <div className="flex flex-wrap">
                                <div className="lg:w-7/12 w-full">
                                    <div className="flex flex-col gap-2.5 py-[20px]">
                                        <div>
                                            <img className="h-5" src={starIcon} alt="" />
                                        </div>
                                        <div>
                                            <span className="p-2 inline-block bg-[#333333] text-[#fff] rounded">Intuitive tools for efficient candidate filtering and accelerated onboarding.</span>
                                        </div>
                                        <h3 className="lg:text-[20px] md:text-[18px] text-[16px] text-white font-medium">Save executive time and resources by quickly identifying the best candidates from a large pool of applicants. Reduce onboarding time and enhance new hire productivity, ensuring they contribute effectively from day one.</h3>
                                        <div>
                                            <a href="#" className="py-3 px-4 border border-[#454545] text-[#98989A] inline-flex gap-2 rounded">Try Now <img src={star} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap lg:absolute top-0 left-0 w-full h-full justify-end">
                            <div className="lg:w-5/12 w-full">
                                <img className="w-full" src={bannerTop2} alt="" style={{ height: '300px' }} />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="relative">
                        <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                            <div className="flex flex-wrap">
                                <div className="lg:w-7/12 w-full">
                                    <div className="flex flex-col gap-2.5 py-[20px]">
                                        <div>
                                            <img className="h-5" src={starIcon} alt="" />
                                        </div>
                                        <div>
                                            <span className="p-2 inline-block bg-[#333333] text-[#fff] rounded">Facilitate skills-led employee mobility and optimize workforce allocation.</span>
                                        </div>
                                        <h3 className="lg:text-[20px] md:text-[18px] text-[16px] text-white font-medium">Promote internal career growth and retention by enabling employees to move into roles where their skills are best utilized. Maximize organizational efficiency by strategically aligning workforce capabilities with business goals and needs.</h3>
                                        <div>
                                            <a href="#" className="py-3 px-4 border border-[#454545] text-[#98989A] inline-flex gap-2 rounded">Try Now <img src={star} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap lg:absolute top-0 left-0 w-full h-full justify-end">
                            <div className="lg:w-5/12 w-full">
                                <img className="w-full" src={bannerTop3} alt="" style={{ height: '300px' }}/>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default Briefinfo;