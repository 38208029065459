
import avatar1 from '../../assets/avatar-img/1.png';
import avatar2 from '../../assets/avatar-img/2.png';
import avatar3 from '../../assets/avatar-img/3.png';
import avatar4 from '../../assets/avatar-img/4.png';
import bannerIcon1 from '../../assets/banner-icon/1.svg';
import bannerIcon2 from '../../assets/banner-icon/2.svg';
import bannerIcon3 from '../../assets/banner-icon/3.svg';
import linkButton from '../../assets/link-icon.svg';
import link from '../../assets/link-icon.png';
import { Link } from 'react-router-dom';
const Banner = () => {

    return (
        <section className="bg-[#141414]">
            <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="lg:w-7/12 w-full">
                        <div className="pt-[107px] md:pb-[70px] pb-[50px] lg:pr-[36px]">
                            <h4 className="font-medium text-[#666666] lg:text-xl md:text-lg text-base">Unlock Top Talent with Third Bracket: Precision Skill Profiling for Superior Hiring and Talent Management</h4>
                            <h3 className="lg:text-[50px] md:text-[40px] text-[30px] text-[#FFFFFF] mb-4" style={{ lineHeight: '1.2' }}>Streamline Your <span className="text-[#F8FA1F]">Recruitment Process and Build High-Performing Teams with Our </span>  Innovative Solutions</h3>
                            <p className="text-[#7E7E81] lg:text-base md:text-sm text-xs">Welcome to the <strong className="text-[#B2B2B2]">Third Bracket!</strong>and discover the power of precise role-to-skill mapping and enhance your workforce efficiency.
                            We believe in conneting the Right Talent at the Right Org in the Right Role....  </p>
                        </div>
                        <div className="flex flex-wrap border-t border-[#262626]">
                            <div className="md:w-3/12 w-6/12">
                                <div className="py-8 px-6 md:pl-0 border-r md:border-b-0 border-b border-[#262626] min-h-full">
                                    {/* <h3 className="text-2xl text-[#fff]">1000<span className="text-[#FFD11A]">+</span></h3> */}
                                    <p className="text-xs text-[#98989A]">Comprehensive Skills Assessments and Role-to-Skill Mapping</p>
                                </div>
                            </div>
                            <div className="md:w-3/12 w-6/12">
                                <div className="py-8 px-6 md:border-r md:border-b-0 border-b border-[#262626] min-h-full">
                                    {/* <h3 className="text-2xl text-[#fff]">3k<span className="text-[#FFD11A]">+</span></h3> */}
                                    <p className="text-xs text-[#98989A]">Cultural Fit Evaluation and Enhanced Candidate Experience</p>
                                </div>
                            </div>
                            <div className="md:w-3/12 w-6/12">
                                <div className="py-8 px-6 border-r md:border-b-0 border-b border-[#262626] min-h-full">
                                    {/* <h3 className="text-2xl text-[#fff]">2k<span className="text-[#FFD11A]">+</span></h3> */}
                                    <p className="text-xs text-[#98989A]">Streamlined Screening Process and Faster Onboarding</p>
                                </div>
                            </div>
                            <div className="md:w-3/12 w-6/12">
                                <div className="py-8 px-6 min-h-full md:border-b-0 border-b border-[#262626]">
                                    {/* <h3 className="text-2xl text-[#fff]">2k<span className="text-[#FFD11A]">+</span></h3> */}
                                    <p className="text-xs text-[#98989A]">Talent Mobility Enablement and Strategic Workforce Planning</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-5/12 w-full">
                        <div className="border-l border-t lg:border-t-0 border-[#262626] banner-img min-h-full bg-cover bg-no-repeat flex flex-col">
                            <div className="md:p-[57px] sm:p-[30px] p-[20px] mt-auto relative flex flex-col gap-5">
                                <div>
                                    <ul className="inline-flex bg-[#202020] p-2 rounded-full"> 
                                        <li><img className="w-10 h-10 border-2 rounded-full object-cover object-center border-[#666666]" src={avatar1} alt="" /></li>
                                        <li className="-ml-2"><img className="w-10 h-10 border-2 rounded-full object-cover object-center border-[#666666]" src={avatar2} alt="" /></li>
                                        <li className="-ml-2"><img className="w-10 h-10 border-2 rounded-full object-cover object-center border-[#666666]" src={avatar3} alt="" /></li>
                                        <li className="-ml-2"><img className="w-10 h-10 border-2 rounded-full object-cover object-center border-[#666666]" src={avatar4} alt="" /></li>
                                    </ul>
                                </div>
                                <div>
                                    <h4 className="text-lg font-medium text-[#fff] pb-3">Explore candidates</h4>
                                    <p className="text-[#98989A] text-xs">Candidates on emerging tech trends and breakthroughs.</p>
                                </div>
                                <div>
                                    <Link to="/book-a-demo" type="button" className="inline-flex gap-2 py-3 px-4 bg-[#202020] rounded-lg text-[#7E7E81] text-xs border border-[#535353]">
                                        Explore Resources <img className="w-4 h-4" src={link} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:border-y border-[#262626]">
                <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                    <div className="flex flex-wrap">
                        <div className="lg:w-4/12 w-full">
                            <div className="sm:py-9 py-6 sm:px-[56px] px-[20px] lg:pl-0 flex flex-col gap-5 lg:border-r border-b lg:border-b-0 border-[#262626]">
                                <div>
                                    <img src={bannerIcon1} alt="" />
                                </div>
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-base text-[#fff]">Skill Profiling</h4>
                                        <p className="text-[#98989A] text-xs">Stay Current</p>
                                    </div>
                                    <a href="#" className="inline-block"><img src={linkButton} alt="" /></a>
                                </div>
                                <p className="text-[#98989A] text-sm">Candidates apply monthly</p>
                            </div>
                        </div>
                        <div className="lg:w-4/12 w-full">
                            <div className="sm:py-9 py-6 sm:px-[56px] px-[20px] flex flex-col gap-5 lg:border-r border-b lg:border-b-0 border-[#262626]">
                                <div>
                                    <img src={bannerIcon2} alt="" />
                                </div>
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-base text-[#fff]">Multiple Job Posting</h4>
                                        <p className="text-[#98989A] text-xs">Trusted Insights</p>
                                    </div>
                                    <a href="#" className="inline-block"><img src={linkButton} alt="" /></a>
                                </div>
                                <p className="text-[#98989A] text-sm">Job Posting at a time</p>
                            </div>
                        </div>
                        <div className="lg:w-4/12 w-full">
                            <div className="sm:py-9 py-6 sm:px-[56px] px-[20px] lg:pr-0 flex flex-col gap-5">
                                <div>
                                    <img src={bannerIcon3} alt="" />
                                </div>
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-base text-[#fff]">Report</h4>
                                        <p className="text-[#98989A] text-xs">See the Impact</p>
                                    </div>
                                    <a href="#" className="inline-block"><img src={linkButton} alt="" /></a>
                                </div>
                                <p className="text-[#98989A] text-sm">A detailed report for each candidate</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;