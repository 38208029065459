import React from "react";
import img from "../../../assets/succesful-image.png";
import { Link } from "react-router-dom";

const StepFive = () => {
  return (
    <section className="py-20 bg-white-sm-light sm:min-h-[calc(100vh-152px)] min-h-[calc(100vh-96px)]">
      <div className="mx-auto max-w-2xl text-center px-4">
        <img className="max-w-xs mx-auto mb-2" src={img} alt="" />
        <h4 className="sm:text-2xl text-xl font-medium sm:mb-6 mb-4">
          Your application has been submitted
        </h4>
        <p className="sm:text-base text-sm text-dark-black md:mb-10 sm:mb-6 mb-4 sm:w-3/5 w-full mx-auto">
          We will send an application status update with this{" "}
          <span className="text-gray-black font-medium">
            rbrajumullah100@gmail.com
          </span>{" "}
          within 1 week.
        </p>
        <Link
          to="/jobs"
          className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-amber-300 font-medium sm:text-base text-sm"
        >
          Back to job page
        </Link>
      </div>
    </section>
  );
};

export default StepFive;
