

import Banner from '../../components/landing02/banner';
import Briefinfo from '../../components/landing02/briefinfo';
import CaseStudies from '../../components/landing02/casestudies';
import Connect from '../../components/landing02/connect';
import Customers from '../../components/landing02/customer';
import Footer from '../../components/landing02/footer';
import Header from '../../components/landing02/header';
import Hiring from '../../components/landing02/hiring';
import Product from '../../components/landing02/product';
import Solutions from '../../components/landing02/solutions';
import Testimonials from '../../components/landing02/testimonials';

const Landing02 = () => {
    

    return (
      <div>
        {/* Header Section */}
        <Header/>
        {/* Banner Section */}
        <Banner/>
        {/* Brief Info Section*/}
        <Briefinfo/>
        {/* Product Section */}
        <Product/>
        {/* Case Studies Section */}
        {/* <CaseStudies/> */}
        {/* Our Solution Section */}
        <Solutions/>
        {/* Hiring Section */}
        <Hiring/>
        {/* Customer Section */}
        {/* <Customers/> */}
        {/* Testimonial Section */}
        {/* <Testimonials/> */}
        {/* Connect Section */}
        <Connect/>
        {/* Footer Section */}
        <Footer/>
      </div>
    );
  };
  
  export default Landing02;