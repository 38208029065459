import { useState } from 'react';
import link from '../../assets/link-icon.png';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

const Header = () => {
    const [menu, setMenu] = useState(false);
    return (
        <div>
            <header className="fixed top-0 left-0 w-full z-40">
                {/* <div className="bg-[#141414] text-center py-2">
                    <h4 className="text-[#98989A] text-xs inline-flex gap-2">Subscribe to our Newsletter For New & latest Blogs and Resources <img className="w-4 h-4" src={link} alt="" /></h4>
                </div> */}
                <div className="bg-[#000000] py-3">
                    <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                        <div className="flex flex-wrap items-center">
                            <div className="lg:w-5/12 w-10/12 flex items-center gap-4">
                                <img className="h-[37px]" src={logo} alt="" />
                                <ul className="inline-flex bg-[#202020] p-2 rounded-lg">
                                    <li>
                                        <a href="/" className="bg-[#141414] inline-block rounded-lg py-2 px-3 border border-[#333333] text-[#fff] text-xs">Home</a>
                                    </li>
                                    <li>
                                        <a href="/#productOur" className="inline-block py-2 rounded-lg px-3 text-[#7E7E81] text-xs">Product</a>
                                    </li>
                                    <li>
                                        <a href="/#ourSolution" className="inline-block py-2 rounded-lg px-3 text-[#7E7E81] text-xs">Solution</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-7/12 w-2/12 text-right">
                                <button 
                                type="button"
                                onClick={() => setMenu(!menu)}
                                className="-m-2.5 inline-flex items-center justify-center lg:hidden rounded-md p-2.5 text-gray-700"
                                >
                                    <svg
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="#fff"
                                    aria-hidden="true"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                    />
                                    </svg>
                                </button>
                                <div className="hidden lg:flex items-center justify-end">
                                    <button type="button" className="inline-flex gap-2 py-3 px-4 bg-[#202020] rounded-lg text-[#7E7E81] text-xs border border-[#535353]">Are you looking for job? <img className="w-4 h-4" src={link} alt="" /></button>
                                    <button type="button" className="bg-[#F8FA1F] py-3 px-4 text-[#141414] text-xs rounded-lg ml-4"><a href='/book-a-demo'>Book a Demo</a></button>
                                    <Link
                                        to="/login"
                                        className="text-lg font-semibold leading-6 text-white"
                                        style={{ paddingLeft: '20px' }}
                                    >
                                        Log in
                                    </Link>
                                    <Link
                                        to="/sign-up"
                                        className="focus:outline-none text-[#141414] bg-[#F8FA1F] hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-lg px-5 py-2.5 ml-12 dark:focus:ring-yellow-900"
                                    >
                                        Sign Up
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Mobile menu, show/hide based on menu open state. */}
                    {menu && (
                        <div
                        id="sidemenu"
                        className="lg:hidden"
                        role="dialog"
                        aria-modal="true"
                        >
                        <div className="fixed inset-0 z-10" />
                        <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-4 py-4 sm:px-6 sm:py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div className="flex items-center justify-end">
                            <button
                                onClick={() => setMenu(!menu)}
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <span className="sr-only">Close menu</span>
                                <svg
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                                </svg>
                            </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="py-6">
                                    <button type="button" className="inline-flex gap-2 py-3 px-4 bg-[#202020] rounded-lg text-[#7E7E81] text-xs border border-[#535353]">Are you looking for job? <img className="w-4 h-4" src={link} alt="" /></button>
                                    <button type="button" className="bg-[#F8FA1F] py-3 px-4 text-[#141414] text-xs rounded-lg ml-4"><a href='/book-a-demo'>Book a Demo</a></button>
                                    <Link
                                        to="/login"
                                        className="text-lg font-semibold leading-6 text-white"
                                        style={{ paddingLeft: '20px' }}
                                    >
                                        Log in
                                    </Link>
                                    <Link
                                        to="/sign-up"
                                        className="focus:outline-none text-[#141414] bg-[#F8FA1F] hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-lg px-5 py-2.5 ml-12 dark:focus:ring-yellow-900"
                                    >
                                        Sign Up
                                    </Link>
                                </div>
                            </div>
                        </div>
                        </div>
                    )}
            </header>
            {/* Header Top Padding */}
            {/* <div className="pt-[114px]"></div> */}
        </div>
    )
}

export default Header;