import React from 'react'

function ProfileSnapshot({ item, idx }: any) {
    return (
        <div className='flex-1' key={idx}>
            <div className={`${item.id == 1 ? 'blue-gradient' : item.id == 2 ? 'red-gradient' : item.id == 3 ? 'purple-gradient' : item.id == 4 ? 'green-gradient' : 'purple-gradient'} p-2.5 rounded-t-lg overflow-hidden`}>
                <div className="flex flex-row justify-between items-center pb-1.5  border-[#F5F5F533] border-b">
                    <div className='flex-row flex flex-1 justify-start gap-1.5 items-center '>
                        <div className='bg-[#EEF0FA33] rounded p-1 w-5 h-5'>
                            <img src={item?.img} alt={item?.title} className='w-full h-full' />
                        </div>
                        <h3 className='text-white text-xs line-clamp-1  flex-1 font-bold'>{item?.title}</h3>
                    </div>
                    <button className={`${(item.id != 4 && item?.id != 5) ? 'hidden' : 'block'} h-4 w-5 rounded-2xl bg-white flex flex-row items-center justify-center`}>
                        <span className='text-black text-xs font-bold'>+</span>
                    </button>
                </div>
                <div className="h-[42px] my-2 flex items-center justify-center">
                    <h3 className='my-0 text-center  text-[#F8FA1F] font-bold text-4xl'>{item?.value}</h3>
                </div>
                <div className="overflow-hidden w-[196px]">
                    <div className="flex-row  gap-1 flex  no-scrollbar cursor-grab overflow-x-auto ">
                        {
                            item?.data.map((value: any, index: number) => {
                                return (
                                    <div className='bg-[#F5F5F533] flex-row flex items-center justify-center   rounded-[9px] px-[7.95px] py-[3.16px]' key={index}>
                                        <span className='text-[9.55px] text-nowrap  font-medium text-white text-center' >
                                            {value}
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='h-8 rounded-b-lg overflow-hidden flex flex-row items-center justify-center bg-[#F9F6F6] p-2.5'>
                <button className='bg-transparent border-none text-center text-[10px]/[12px] font-medium text-[#5685FD]'>
                    <span>View All</span>
                </button>
            </div>
        </div>
    )
}

export default ProfileSnapshot