import React from 'react';

const StepThreeContent = ({
  nextStep,
  prevStep,
  questions,
  selectedAnswers,
  handleAnswerSelect,
  loading,
}: any) => {
  const [activeQIdx, setActiveQIdx] = React.useState(0);
  const [maxIndex, setMaxIndex] = React.useState(-1);
  const [questionStatus, setQuestionStatus] = React.useState(
    Array(questions.length).fill('default')
  );
  const [maxVisitedIndex, setMaxVisitedIndex] = React.useState(0);
  const nextQ = () => {
    setActiveQIdx((prevIdx) => ++prevIdx);
    setMaxVisitedIndex(maxVisitedIndex + 1);
  };
  const prevQ = () => {
    setActiveQIdx((prevIdx) => --prevIdx);
  };

  const getQuestionNumberStyle = (questionIdx: any) => {
    const status = questionStatus[questionIdx];
    const isActive = questionIdx === activeQIdx;

    if (isActive) {
      return 'bg-amber-300 border-amber-300';
    } else {
      switch (status) {
        case 'default':
          return 'border-light-black';
        case 'answered':
          return 'bg-green-500 text-white border-green-500';
        case 'unanswered':
          return 'border-red-500 text-red-500';
        default:
          return 'border-light-black';
      }
    }
  };

  React.useEffect(() => {
    const initialStatus = questions.map((question: any, index: any) => {
      if (selectedAnswers[index]) {
        return 'answered';
      } else {
        return 'default';
      }
    });

    setQuestionStatus(initialStatus);

    const unansweredQuestionIndices: number[] = [];

    for (let i = 0; i <= Math.max(activeQIdx, maxIndex); i++) {
      if (!selectedAnswers[i]) {
        unansweredQuestionIndices.push(i);
      }
    }

    setMaxIndex(Math.max(maxIndex, activeQIdx));

    const updatedStatus = initialStatus.map((status: string, index: number) => {
      if (unansweredQuestionIndices.includes(index)) {
        return 'unanswered';
      }
      return status;
    });

    setQuestionStatus(updatedStatus);

    // console.log('Unanswered Question Indices:', unansweredQuestionIndices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, selectedAnswers, activeQIdx]);
  return (
    <section className="md:py-7 py-5 bg-light-white min-h-[calc(100vh-152px)]">
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-400 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Analyzing your profile...
          </h2>
          <p className="w-1/3 text-center text-white">
            This may take a few seconds, please don't close this page.
          </p>
        </div>
      )}
      <div className="mx-auto max-w-7xl px-4">
        <div className="flex justify-between flex-wrap">
          <div className="lg:w-3/12 w-full lg:order-2">
            <div className="sm:p-5 p-4 rounded-2xl bg-white box-shadow-medium max-w-xs mx-auto mb-6">
              <p className="md:text-base text-sm font-medium mb-4">
                Answer Status
              </p>
              <div className="grid grid-cols-4 gap-2">
                {questions?.map((question: any, questionIdx: number) => (
                  <div
                    key={questionIdx}
                    className={`inline-block h-10 w-10 md:leading-10 leading-10 rounded-lg text-center border-2 md:text-base text-sm font-medium ${getQuestionNumberStyle(
                      questionIdx
                    )}`}
                    onClick={(event) => {
                      event.preventDefault();
                      setActiveQIdx(questionIdx);
                    }}
                  >
                    {questionIdx + 1}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="lg:w-8/12 w-full lg:order-1">
            <h3 className="font-bold text-xl my-3">
              Question {activeQIdx + 1} ~ {questions[activeQIdx]?.skill}
            </h3>
            <div className="md:mb-8 mb-6">
              <h4 className="md:text-base text-sm font-medium mb-4">
                {questions && questions[activeQIdx]?.title}
              </h4>
              <ul>
                {questions &&
                  questions[activeQIdx]?.answers?.map(
                    (answer: any, idx: number) => (
                      <li key={idx}>
                        <input
                          type="checkbox"
                          name={`question_${activeQIdx}`}
                          checked={selectedAnswers[activeQIdx]?.some(
                            (selectedAnswer: any) =>
                              selectedAnswer.answerId === answer._id
                          )}
                          onChange={() => handleAnswerSelect(activeQIdx, idx)}
                          className="answer-checkbox mx-6 my-2"
                        />
                        {answer?.title}
                      </li>
                    )
                  )}
              </ul>
            </div>
            <div className="md:mt-10 mt-7">
              {activeQIdx !== 0 && (
                <div
                  onClick={prevQ}
                  className="cursor-pointer inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-shade-gray font-medium md:text-base text-sm"
                >
                  Previous
                </div>
              )}
              <div
                onClick={activeQIdx === questions.length - 1 ? nextStep : nextQ}
                className="cursor-pointer inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-amber-300 font-medium md:text-base text-sm ml-6"
              >
                Next
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepThreeContent;
