import React from "react";
import DashboardLayout from "../../layout/DashboardLayout";
import MainContent from "../../components/Courses/MainContent";

const Courses = () => {
  return (
    <DashboardLayout>
      <MainContent />
    </DashboardLayout>
  );
};

export default Courses;
