import React, { useState } from "react";
import MainLayout from "../../layout/MainLayout";
import ApplyJobHeader from "../../components/ApplyJob/ApplyJobHeader";
import StepOne from "../../components/ApplyJob/steps/StepOne";
import StepTwo from "../../components/ApplyJob/steps/StepTwo";
import StepThree from "../../components/ApplyJob/steps/StepThree";
import StepFour from "../../components/ApplyJob/steps/StepFour";
import StepFive from "../../components/ApplyJob/steps/StepFive";

const ApplyJob = () => {
  const [step, setStep] = useState(0);
  const [progress, setProgress] = useState(0);
  React.useEffect(() => {
    switch (step) {
      case 0:
        setProgress(20);
        break;
      case 1:
        setProgress(40);
        break;
      case 2:
        setProgress(60);
        break;
      case 3:
        setProgress(80);
        break;
      case 4:
        setProgress(100);
        break;

      default:
        break;
    }
  }, [step]);

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  return (
    <MainLayout headerProgress={progress} headerContent={<ApplyJobHeader />}>
      {(() => {
        switch (step) {
          case 0:
            return <StepOne nextStep={nextStep} />;
          case 1:
            return <StepTwo prevStep={prevStep} nextStep={nextStep} />;
          case 2:
            return <StepThree prevStep={prevStep} nextStep={nextStep} />;
          case 3:
            return <StepFour prevStep={prevStep} nextStep={nextStep} />;
          case 4:
            return <StepFive />;

          default:
            break;
        }
      })()}
    </MainLayout>
  );
};

export default ApplyJob;
