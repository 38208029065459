import React from "react";
import { Link, useLocation } from "react-router-dom";
import arrowLeftLong from "../../assets/arrow-left-long.svg";
import courseDetailsImg from "../../assets/course-details-img.png";
import userProfile from "../../assets/user-profile.png";
import arrowDown from "../../assets/arrow-down.svg";
import playIcon from "../../assets/play-circle-icon.png";

const MainContent = () => {
  const { state: course } = useLocation();
  return (
    <section className="dasboard-content lg:w-[calc(100%-15rem)] bg-light-sm min-h-[calc(100vh-104px)] sm:px-6 px-3 sm:py-7 py-4">
      <div className="flex justify-between">
        <div>
          <Link
            to="/courses"
            className="sm:py-4 py-2 sm:px-4 px-3 bg-dark-gray rounded-lg inline-flex items-center font-medium sm:text-base text-sm md:mb-8 sm:mb-6 mb-4"
          >
            <img
              className="sm:mr-2 mr-1 sm:w-5 w-4"
              src={arrowLeftLong}
              alt=""
            />{" "}
            Back
          </Link>
        </div>
        <div>
          <Link
            to="/start-learning"
            className="bg-amber-300 text-base md:py-3.5 sm:py-3 py-2 md:px-5 sm:px-4 px-3 rounded-lg text-center font-semibold inline-block"
          >
            Start Learning
          </Link>
        </div>
      </div>
      <div className="flex flex-wrap -mx-5">
        <div className="lg:w-8/12 w-full px-5">
          <h3 className="md:text-2xl text-xl text-gray-black font-medium mb-4">
            {course.title}
          </h3>
          <img className="w-full sm:mb-4 mb-2" src={course.thumbnail} alt="" />
          <div className="flex md:mb-3 mb-2">
            <svg
              className="md:w-6 w-5 md:h-6 h-5 mr-2"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                fill="#F3DA38"
              />
            </svg>
            <svg
              className="md:w-6 w-5 md:h-6 h-5 mr-2"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                fill="#F3DA38"
              />
            </svg>
            <svg
              className="md:w-6 w-5 md:h-6 h-5 mr-2"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                fill="#F3DA38"
              />
            </svg>
            <svg
              className="md:w-6 w-5 md:h-6 h-5 mr-2"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                fill="#F3DA38"
              />
            </svg>
            <svg
              className="md:w-6 w-5 md:h-6 h-5 mr-2"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                fill="#F3DA38"
              />
            </svg>
            <span className="text-light-black lg:text-base text-sm">
              5.0 (25 reviews)
            </span>
          </div>
          <div className="flex items-center md:mb-6 mb-4">
            <div className="mr-2">
              <img
                className="w-6 h-6 rounded-full object-cover object-center"
                src={userProfile}
                alt=""
              />
            </div>
            <h4 className="lg:text-base text-sm ">Raju</h4>
          </div>
          <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 md:mb-6 mb-4">
            <ul className="flex flex-wrap -mb-px">
              <li className="md:mr-20 sm:mr-14 mr-8">
                <a
                  href="courses.html"
                  className="inline-block pb-4 border-b-4 text-gray-black border-amber-300 md:text-base text-sm font-semibold active"
                >
                  Overview
                </a>
              </li>
              <li className="md:mr-20 sm:mr-14 mr-8">
                <a
                  href="/"
                  className="inline-block pb-4 border-b-4 border-transparent md:text-base text-sm"
                >
                  Completed
                </a>
              </li>
              <li className="md:mr-20 sm:mr-14 mr-8">
                <a
                  href="/"
                  className="inline-block pb-4 border-b-4 md:text-base text-sm border-transparent"
                  aria-current="page"
                >
                  Reviews
                </a>
              </li>
            </ul>
          </div>
          <h4 className="md:text-xl text-base mb-2 font-medium">
            About this course
          </h4>
          <p className="md:mb-6 mb-4 md:text-base text-sm text-dark-black">
            Learn to quickly connect with crowds of strangers, be better on
            dates or ace that job interview.
          </p>
          <div className="flex flex-wrap -mx-4 md:mb-6 mb-4">
            <div className="sm:w-3/12 w-full px-4">
              <h4 className="md:text-base text-sm font-semibold">
                By the numbers
              </h4>
            </div>
            <div className="sm:w-9/12 w-full px-4">
              <div className="flex flex-wrap -mx-3">
                <div className="sm:w-6/12 w-full px-3">
                  <p className="md:text-base text-sm text-light-black mb-1">
                    Skill level: All Levels
                  </p>
                  <p className="md:text-base text-sm text-light-black mb-1">
                    Total enroll: 24
                  </p>
                  <p className="md:text-base text-sm text-light-black mb-1">
                    Languages: English
                  </p>
                  <p className="md:text-base text-sm text-light-black mb-1">
                    Caption: Yes
                  </p>
                </div>
                <div className="sm:w-6/12 w-full px-3">
                  <p className="md:text-base text-sm text-light-black mb-1">
                    Total Lectures: 25
                  </p>
                  <p className="md:text-base text-sm text-light-black mb-1">
                    Course Duration: {course.duration}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4 sm:mb-6 mb-3">
            <div className="sm:w-3/12 w-full px-4">
              <h4 className="md:text-base text-sm font-semibold">
                Description
              </h4>
            </div>
            <div className="sm:w-9/12 w-full px-4">
              <h4 className="md:text-base text-sm text-light-black font-medium sm:mb-6 mb-3">
                The goals of this course are simple:
              </h4>
              <p className="md:text-base text-sm text-light-black sm:mb-6 mb-3">
                {course.desc}
              </p>
              {/* <p className="md:text-base text-sm text-light-black sm:mb-6 mb-3">
                If you're an entrepreneur with a great product and need to get
                better at your pitches, then TAKE THIS COURSE!{" "}
              </p>
              <p className="md:text-base text-sm text-light-black sm:mb-6 mb-3">
                If you're an entrepreneur with a great product and need to get
                better at your pitches, then TAKE THIS COURSE!{" "}
              </p>
              <p className="md:text-base text-sm text-light-black sm:mb-6 mb-3">
                If you're an entrepreneur with a great product and need to get
                better at your pitches, then TAKE THIS COURSE!{" "}
              </p>
              <p className="md:text-base text-sm text-light-black sm:mb-6 mb-3">
                If you're an entrepreneur with a great product and need to get
                better at your pitches, then TAKE THIS COURSE!{" "}
              </p> */}
            </div>
          </div>
        </div>
        <div className="lg:w-4/12 w-full px-5">
          <div className="md:p-6 p-4 bg-white rounded-2xl mb-4">
            <h4 className="font-medium md:text-xl text-base">Course Content</h4>
          </div>
          <div className="md:p-6 p-4 bg-white rounded-2xl">
            <div className="flex justify-between mb-6">
              <h4 className="font-semibold md:text-base text-sm">Chapter 1</h4>
              <a href="/">
                <img className="sm:w-6 w-4 sm:h-6 h-4" src={arrowDown} alt="" />
              </a>
            </div>
            <div className="flex sm:p-2.5 p-2 rounded-xl hover:bg-yellow-100 transition-all mb-2">
              <input
                type="checkbox"
                className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
              />
              <div>
                <h4 className="md:text-base text-sm mb-1">Intro</h4>
                <p className="flex sm:text-sm text-xs text-dark-black items-center">
                  <img className="w-4 h-4 mr-1" src={playIcon} alt="" />2 min
                </p>
              </div>
            </div>
            <div className="flex sm:p-2.5 p-2 rounded-xl hover:bg-yellow-100 transition-all mb-2">
              <input
                type="checkbox"
                className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
              />
              <div>
                <h4 className="md:text-base text-sm mb-1">Content 2</h4>
                <p className="flex md:text-sm text-xs text-dark-black items-center">
                  <img className="w-4 h-4 mr-1" src={playIcon} alt="" />2 min
                </p>
              </div>
            </div>
            <div className="flex sm:p-2.5 p-2 rounded-xl hover:bg-yellow-100 transition-all">
              <input
                type="checkbox"
                className="mr-2 sm:h-5 h-4 sm:w-5 w-4 rounded border-2 answer-checkbox answer-check-sm border-gray-black"
              />
              <div>
                <h4 className="md:text-base text-sm mb-1">Content 2</h4>
                <p className="flex md:text-sm text-xs text-dark-black items-center">
                  <img className="w-4 h-4 mr-1" src={playIcon} alt="" />2 min
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainContent;
