import React, { useState, useEffect } from 'react';
import { axiosPublic } from '../../api/axios';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

// Define the interface for the data
interface CertificateData {
    assessmentTitle?: string;
    name?: string;
}

interface MainContentProps {
    id: string;
}

const MainContent: React.FC<MainContentProps> = ({ id }) => {
    const [data, setData] = useState<CertificateData>({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axiosPublic.get(`/certificate-detail/${id}`);
                setData(res.data);
            } catch (error) {
                console.error('Error fetching certificate details:', error);
            }
        };
        fetchData();
    }, [id]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <style>
                {`
                    body,
                    html {
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 100%;
                    }

                    p {
                        font-family: 'Podkova', serif;
                        text-align: center;
                        margin: 0;
                        white-space: nowrap;
                    }

                    @media (min-width: 400px) {
                        p {
                            font-size: 20px;
                        }
                    }

                    @media (min-width: 601px) {
                        p {
                            font-size: 25px;
                        }
                    }

                     /* Media query for small screens */
                    @media (max-width: 400px) {
                        .name-text {
                            top: 42%;
                            left: 8%;
                            font-size: 12px;
                            position: absolute;
                        }
                    }

                    /* Media query for medium screens */
                    @media (min-width: 401px) and (max-width: 800px) {
                        .name-text {
                            top: 48%;
                            left: 25%;
                            font-size: 18px;
                            position: absolute;
                        }
                    }

                    /* Media query for large screens */
                    @media (min-width: 801px) {
                        .name-text {
                            top: 47%;
                            left: 25%;
                            font-size: 50px;
                            position: absolute;
                        }
                    }

                    .name-text span {
                        display: block;
                        white-space: nowrap;
                    }
                `}
            </style>
            <table
                cellPadding={0}
                cellSpacing={0}
                border={0}
                style={{ width: '100%', maxWidth: '100%', margin: 'auto' }}
            >
                <tbody>
                    <tr>
                        <td style={{ padding: 0, position: 'relative' }}>
                            <img
                                src="https://third-bracket.s3.eu-north-1.amazonaws.com/0.029283381473754977-thmb-1710174795122"
                                alt="Certificate background"
                                style={{ display: 'block', width: '100%', height: 'auto' }}
                            />
                            <p className="name-text">
                                Name: {data.name} <span> Skill Profile For: {data.assessmentTitle}</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default MainContent;
