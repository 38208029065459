// Results.tsx
import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../shared/Header";

interface Job {
  companyDetails: string;
  requiredQualification: string;
  annualCtc: string;
  requiredYearsOfExperience: string;
  jobRole: any;
  // Define your job data structure here
  id: string;
  title: string;
  jobDetails: string
  // Add other job properties as needed
}

interface JobSectionProps {
  title: string;
  value: string | string[];
}

const JobSection: React.FC<JobSectionProps> = ({ title, value }) => (
  <p>
    <span style={{ fontWeight: 'bold' }}>{title}:</span> {value}
  </p>
);

const Results: React.FC = () => {
  const location = useLocation();
  const { jobs, skills, location: locationParam, experience } = location.state || {};

  return (
    <div>
      <Header />
      <p style={{ fontSize: '32px', lineHeight: '1.6', marginTop: '20px', fontFamily: 'Arial, sans-serif' }}>
        Search Results
      </p>
      <div style={{ backgroundColor: "#f0f0f0", padding: "20px" }}>
        {jobs && jobs.length > 0 ? (
          <ul>
            {jobs.map((job: Job) => (
              <li key={job.id} style={{ backgroundColor: "white", margin: "10px 0", padding: "20px", borderRadius: "8px" }}>
                {/* Use JobSection component for each job section */}
                <JobSection title="Job Title" value={job.title} />
                <JobSection title="Job Details" value={job.jobDetails} />
                <JobSection title="Company Name" value={job.companyDetails} />
                <JobSection title="Required Qualification" value={job.requiredQualification} />
                <JobSection title="CTC" value={job.annualCtc} />
                <JobSection title="Experience(in years)" value={job.requiredYearsOfExperience} />
                <JobSection title="Skills" value={job.jobRole.skills.join(', ')} />

                <p style={{ fontSize: '18px', lineHeight: '1.6', marginTop: '20px', fontFamily: 'Arial, sans-serif' }}>
                  {/* Add job details text */}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p style={{ fontSize: '18px', lineHeight: '1.6', marginTop: '20px', fontFamily: 'Arial, sans-serif' }}>
            No Matching Jobs Found!
          </p>
        )}
      </div>
    </div>
  );
  
};

export default Results;
