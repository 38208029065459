import { Swiper, SwiperSlide } from 'swiper/react';
import productImg1 from '../../assets/product-img/product-thumb/1.jpg';
import report from '../../assets/product-img/product-thumb/report.jpg';
import dasobaordResult from '../../assets/product-img/product-thumb/dasobaord-result.png';
import assesment from '../../assets/product-img/product-thumb/assesment.png';
import jobRole from '../../assets/product-img/product-thumb/jobRole.png';
import userDashboard from '../../assets/product-img/product-thumb/userDashboard.png';
import graph from '../../assets/product-img/product-thumb/graph1.jpg';
import onboard from '../../assets/product-img/product-thumb/onboard.webp';
import productImg9 from '../../assets/product-img/product-thumb/9.jpg';
import lighting from '../../assets/product-img/lightingimg.svg';
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper/modules';


const Product = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <section className="bg-[#000000] py-[60px]" id='productOur'>
            <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                <div className="flex items-center justify-between lg:mb-[43px] md:mb-[30px] mb-[20px]">
                    <h3 className="lg:text-[25px] md:text-[20px] text-[16px] font-semibold text-white">Our Product</h3>
                    <a href="#" className="inline-block px-4 py-2.5 bg-[#F8FA1F] rounded-lg">Book a Demo</a>
                </div>
                <div className="bg-[#1A1A1A] lg:p-[43px] md:p-[30px] p-[20px] rounded-xl">
                    <div className="p-[17px] border border-[#262626] rounded-lg mb-6">
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            breakpoints={{
                                0: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },
                                640: {
                                  slidesPerView: 7,
                                  spaceBetween: 12,
                                },
                                1024: {
                                  slidesPerView: 9,
                                  spaceBetween: 17,
                                },
                            }}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <img className="h-[80px] opacity-60 rounded w-full object-cover object-center" src={productImg1} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="h-[80px] opacity-60 rounded w-full object-cover object-center" src={userDashboard} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="h-[80px] opacity-60 rounded w-full object-cover object-center" src={graph} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="h-[80px] opacity-60 rounded w-full object-cover object-center" src={report} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img className="h-[80px] opacity-60 rounded w-full object-cover object-center" src={onboard} alt="" />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    
                    <Swiper
                        spaceBetween={10}
                        navigation={true}
                        pagination={true}
                        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
                        modules={[FreeMode, Navigation, Thumbs, Pagination]}
                        className="productSilder"
                        >
                        <SwiperSlide>
                            <div className="flex flex-wrap -mx-6">
                                <div className="lg:w-8/12 px-6">
                                    <img className="w-full h-[420px] object-cover object-top rounded-lg" src={productImg1} alt="" style={{ objectFit: 'contain' }}/>
                                </div>
                                <div className="lg:w-4/12 px-6">
                                    <div className="border border-[#262626] rounded-lg pt-4 lg:pb-[43px] md:pb-[30px] pb-20 min-h-full lg:px-[43px] md:px-[30px] px-[20px]">
                                        <h4 className="text-xl text-white font-semibold mb-6">Efficient Talent Acquisition</h4>
                                        <div className="flex flex-col gap-6">
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Reduce time-to-hire and cost-per-hire with our role-to-skill mapping. </p>
                                            </div>
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Identify the perfect fit for every role </p>
                                            </div>
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">quickly and efficiently </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-wrap -mx-6">
                                <div className="lg:w-8/12 px-6">
                                    <img className="w-full h-[420px] object-cover object-top rounded-lg" src={userDashboard} alt="" style={{ objectFit: 'contain' }}/>
                                </div>
                                <div className="lg:w-4/12 px-6">
                                    <div className="border border-[#262626] rounded-lg pt-4 lg:pb-[43px] md:pb-[30px] pb-20 min-h-full lg:px-[43px] md:px-[30px] px-[20px]">
                                        <h4 className="text-xl text-white font-semibold mb-6">Comprehensive Skills Assessments</h4>
                                        <div className="flex flex-col gap-6">
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Our platform offers detailed evaluations tailored to specific job requirements </p>
                                            </div>
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">ensuring you have the right talent for every position </p>
                                            </div>
                                            {/* <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-wrap -mx-6">
                                <div className="lg:w-8/12 px-6">
                                    <img className="w-full h-[420px] object-cover object-top rounded-lg" src={graph} alt="" style={{ objectFit: 'contain' }}/>
                                </div>
                                <div className="lg:w-4/12 px-6">
                                    <div className="border border-[#262626] rounded-lg pt-4 lg:pb-[43px] md:pb-[30px] pb-20 min-h-full lg:px-[43px] md:px-[30px] px-[20px]">
                                        <h4 className="text-xl text-white font-semibold mb-6">Enhanced Cultural Fit</h4>
                                        <div className="flex flex-col gap-6">
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Assess candidates for cultural alignment to enhance team integration and retention </p>
                                            </div>
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">creating a harmonious and productive work environment </p>
                                            </div>
                                            {/* <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-wrap -mx-6">
                                <div className="lg:w-8/12 px-6">
                                    <img className="w-full h-[420px] object-cover object-top rounded-lg" src={report} alt="" style={{ objectFit: 'contain' }}/>
                                </div>
                                <div className="lg:w-4/12 px-6">
                                    <div className="border border-[#262626] rounded-lg pt-4 lg:pb-[43px] md:pb-[30px] pb-20 min-h-full lg:px-[43px] md:px-[30px] px-[20px]">
                                        <h4 className="text-xl text-white font-semibold mb-6">Streamlined Screening Process</h4>
                                        <div className="flex flex-col gap-6">
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Save executive time with our intuitive tools, which filter candidates based on technical, functional, and behavioral skills </p>
                                            </div>
                                            {/* <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                                            </div>
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p> 
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-wrap -mx-6">
                                <div className="lg:w-8/12 px-6">
                                    <img className="w-full h-[420px] object-cover object-top rounded-lg" src={onboard} alt="" style={{ objectFit: 'contain' }}/>
                                </div>
                                <div className="lg:w-4/12 px-6">
                                    <div className="border border-[#262626] rounded-lg pt-4 lg:pb-[43px] md:pb-[30px] pb-20 min-h-full lg:px-[43px] md:px-[30px] px-[20px]">
                                        <h4 className="text-xl text-white font-semibold mb-6">Accelerated Onboarding</h4>
                                        <div className="flex flex-col gap-6">
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Get new hires up to speed faster with deep insights into their skills and competencies </p>
                                            </div>
                                            <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Ensuring they hit the ground running from day one </p>
                                            </div>
                                            {/* <div className="bg-gradient-to-r from-[#1A1A1A] to-[#1a1a1a00] border-l border-[#703BF7] px-5 py-4 flex items-center gap-3">
                                                <img src={lighting} alt="" />
                                                <p className="text-base text-[#999999]">Lorem ipsum dolor sit amet consectetur. Et tellus cursus </p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default Product;