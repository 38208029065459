import React from "react";
import MainLayoutHeader from "../components/Layouts/MainLayout/MainLayoutHeader";

type Props = {
  children: React.ReactNode;
  headerProgress: number;
  headerContent: React.ReactNode;
};
const MainLayout = ({ children, headerProgress, headerContent }: Props) => {
  return (
    <>
      <MainLayoutHeader progress={headerProgress}>
        {headerContent}
      </MainLayoutHeader>
      {children}
    </>
  );
};

export default MainLayout;
