import React from 'react';
import c_1 from '../../../../assets/courses/One.jpeg';
import c_2 from '../../assets/courses/Two.jpeg';
import c_3 from '../../assets/courses/Three.jpeg';
import c_4 from '../../assets/courses/Four.jpeg';
import profile from '../../../../assets/user-proflie-1.png';
// import { Link } from "react-router-dom";

const RecommendedCourses = ({ courses }: any) => {
  //   const courses = [
  //     {
  //       title: "Sales Management - Business Ethics and Sales",
  //       desc: `This online course in sales management will teach you about business ethics and sales. Management needs to ensure that all employees have a good understanding of the proper behaviour and etiquette that is expected from them in a sales environment. This business ethics course will teach you how to make a positive impression on your customers, and will guide you through some effective communication techniques essential to the sales industry`,
  //       duration: `1.5-3 Hours of Learning`,
  //       thumbnail: c_1,
  //     },
  //     {
  //       title: "Sales Techniques - Using Competitive Sales Strategies",
  //       desc: `This  online course in sales techniques will teach you how to use competitive sales strategies, how to negotiate in mutual interest and how to write superior sales proposals. Selling is an essential to the success of any business. This free online course will teach you about what compels customers to make a purchase, customer politics and buying cycles. Take this course today to improve your abilities as a salesman`,
  //       duration: `1.5-3 Hours of Learning`,
  //       thumbnail: c_2,
  //     },
  //     {
  //       title: "Effective Leadership Skills and Strategies",
  //       desc: `Leadership entails the ability to influence others towards attaining a common goal. The hallmark of any great leader is the success of each individual member of the team. However, every team comprises individuals with diverse backgrounds and personal philosophies. The primary responsibility of every good leader is to consider these individual differences and harness them towards achieving the team’s objective and success.`,
  //       duration: `1.5-3 Hours of Learning`,
  //       thumbnail: c_3,
  //     },
  //     {
  //       title: "Leadership & Management Skills For Business - Managing Employees",
  //       desc: `In this free online leadership and management course you will learn about the challenges facing companies that can make or break a business. You will learn the specific leadership and management tools, strategies and techniques to beat these challenges. In this training you will study the 4 key areas to business success 1) Leadership skills 2) Management skills 3) How to create a great business culture 4) How to build high performance teams`,
  //       duration: `1.5-3 Hours of Learning`,
  //       thumbnail: c_4,
  //     },
  //   ];
  return (
    <section className="sm:py-20 py-10 bg-light-white">
      <div className="relative isolate mx-auto max-w-7xl px-4">
        <div className="flex flex-wrap lg:-mx-6 md:mb-10 mb-6">
          <div className="md:w-2/4 w-full px-6 md:text-left text-center md:mb-0 mb-6">
            <h3 className="md:text-3xl text-2xl text-gray-black font-medium mb-4">
              Courses
            </h3>
            <p className="text-light-black md:text-base text-sm">
              Assessment lays the crucial foundation for self awareness and
              success in any role.
            </p>
          </div>
          <div className="md:w-2/4 w-full px-6 md:text-right text-center">
            <a
              href="/"
              className="inline-flex items-center border width-auto lg:text-base text-sm text-gray-black rounded-xl border-gray-black px-4 py-2 sm:px-6 sm:py-4"
            >
              View All
              <svg
                className="ml-2"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 20L20 4M20 4H7.2M20 4V16.8"
                  stroke="#0A1026"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-wrap lg:-mx-5">
          {courses?.map((course: any, idx: number) => {
            return (
              <a
                href={course?.courseLink || '/'}
                className="block lg:w-4/12 w-full lg:px-5 sm:mb-8 mb-6"
                key={idx}
              >
                <div className="bg-white sm:p-6 p-4 rounded-3xl box-shadow-light">
                  <div className="mb-4">
                    <img
                      className="w-full rounded-3xl h-60 object-cover object-center"
                      src={c_1}
                      alt=""
                    />
                  </div>
                  <div>
                    <h3 className="lg:text-2xl sm:text-xl text-base mb-4 font-medium">
                      {course.title}
                    </h3>
                    <div className="flex mb-4">
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                          fill="#F3DA38"
                        />
                      </svg>
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                          fill="#F3DA38"
                        />
                      </svg>
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                          fill="#F3DA38"
                        />
                      </svg>
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2"
                      >
                        <path
                          d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                          fill="#F3DA38"
                        />
                      </svg>
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-3"
                      >
                        <path
                          d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                          fill="#F3DA38"
                        />
                      </svg>
                      {/* <span className="text-light-black lg:text-base text-sm">
                        5.0 (25 reviews)
                      </span> */}
                    </div>
                    <p className="lg:text-base text-sm mb-4 text-light-black">
                      Course Duration: {course.duration}
                    </p>
                    <div className="flex items-center">
                      <div className="mr-2">
                        <img src={profile} alt="" />
                      </div>
                      {/* <h4 className="lg:text-base text-sm">Raju</h4> */}
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
          {/* <div className="lg:w-4/12 w-full lg:px-5 sm:mb-8 mb-6">
            <div className="bg-white sm:p-6 p-4 rounded-3xl box-shadow-light">
              <div className="mb-4">
                <img
                  className="w-full rounded-3xl h-60 object-cover object-center"
                  src={c_2}
                  alt=""
                />
              </div>
              <div>
                <h3 className="lg:text-2xl sm:text-xl text-base mb-4 font-medium">
                  Become a expert Figma Designers in 3 months
                </h3>
                <div className="flex mb-4">
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <span className="text-light-black lg:text-base text-sm">
                    5.0 (25 reviews)
                  </span>
                </div>
                <p className="lg:text-base text-sm mb-4 text-light-black">
                  Course Duration: 1hr 50mins
                </p>
                <div className="flex items-center">
                  <div className="mr-2">
                    <img src={profile} alt="" />
                  </div>
                  <h4 className="lg:text-base text-sm">Raju</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-4/12 w-full lg:px-5 sm:mb-8 mb-6">
            <div className="bg-white sm:p-6 p-4 rounded-3xl box-shadow-light">
              <div className="mb-4">
                <img
                  className="w-full rounded-3xl h-60 object-cover object-center"
                  src={c_3}
                  alt=""
                />
              </div>
              <div>
                <h3 className="lg:text-2xl sm:text-xl text-base mb-4 font-medium">
                  Become a expert Figma Designers in 3 months
                </h3>
                <div className="flex mb-4">
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <svg
                    width={21}
                    height={20}
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3"
                  >
                    <path
                      d="M11.7299 1.50965L13.4899 5.02965C13.7299 5.51966 14.3699 5.98965 14.9099 6.07965L18.0999 6.60965C20.1399 6.94965 20.6199 8.42964 19.1499 9.88964L16.6699 12.3696C16.2499 12.7896 16.0199 13.5996 16.1499 14.1796L16.8599 17.2496C17.4199 19.6796 16.1299 20.6196 13.9799 19.3496L10.9899 17.5796C10.4499 17.2596 9.55994 17.2596 9.00994 17.5796L6.01991 19.3496C3.87991 20.6196 2.57991 19.6696 3.13991 17.2496L3.84991 14.1796C3.97991 13.5996 3.74991 12.7896 3.32991 12.3696L0.849909 9.88964C-0.610091 8.42964 -0.140091 6.94965 1.89991 6.60965L5.08991 6.07965C5.61991 5.98965 6.25991 5.51966 6.49991 5.02965L8.25994 1.50965C9.21994 -0.400352 10.7799 -0.400352 11.7299 1.50965Z"
                      fill="#F3DA38"
                    />
                  </svg>
                  <span className="text-light-black lg:text-base text-sm">
                    5.0 (25 reviews)
                  </span>
                </div>
                <p className="lg:text-base text-sm mb-4 text-light-black">
                  Course Duration: 1hr 50mins
                </p>
                <div className="flex items-center">
                  <div className="mr-2">
                    <img src={profile} alt="" />
                  </div>
                  <h4 className="lg:text-base text-sm">Raju</h4>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default RecommendedCourses;
