import React from "react";
import { Link } from "react-router-dom";
import arrowLeft from "../../assets/arrow-left-long.svg";

const StepTwo = ({ prevStep }: any) => {
  return (
    <section className="dasboard-content lg:w-[calc(100%-15rem)] w-full bg-light-sm sm:min-h-[calc(100vh-104px)] min-h-[calc(100vh-72px)] sm:px-6 px-4 sm:py-7 py-4">
      <Link
        to="/assessments"
        className="sm:py-4 py-2 sm:px-4 px-3 bg-dark-gray rounded-lg inline-flex items-center font-medium sm:text-base text-sm md:mb-8 sm:mb-6 mb-4"
      >
        <img className="sm:mr-2 mr-1 sm:w-5 w-4" src={arrowLeft} alt="" /> Back
      </Link>
      <div className="mx-auto max-w-3xl">
        <div className="md:p-8 sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl sm:mb-6 mb-4">
          <form action="#" method="GET">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Question type
              </label>
              <div className="mt-2">
                <select
                  name=""
                  id=""
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                >
                  <option value="">Multiple choice question</option>
                  <option value="">Multiple choice question</option>
                  <option value="">Multiple choice question</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Question
              </label>
              <div className="mt-2 flex items-center">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                />
                <svg
                  className="sm:ml-4 ml-3 sm:w-8 w-6 sm:h-8 h-6"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0003 29.3337H20.0003C26.667 29.3337 29.3337 26.667 29.3337 20.0003V12.0003C29.3337 5.33366 26.667 2.66699 20.0003 2.66699H12.0003C5.33366 2.66699 2.66699 5.33366 2.66699 12.0003V20.0003C2.66699 26.667 5.33366 29.3337 12.0003 29.3337Z"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9997 13.3333C13.4725 13.3333 14.6663 12.1394 14.6663 10.6667C14.6663 9.19391 13.4725 8 11.9997 8C10.5269 8 9.33301 9.19391 9.33301 10.6667C9.33301 12.1394 10.5269 13.3333 11.9997 13.3333Z"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.55957 25.2671L10.1329 20.8537C11.1862 20.1471 12.7062 20.2271 13.6528 21.0404L14.0928 21.4271C15.1328 22.3204 16.8128 22.3204 17.8528 21.4271L23.3995 16.6671C24.4395 15.7737 26.1195 15.7737 27.1595 16.6671L29.3328 18.5337"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Answer
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Option
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                />
              </div>
            </div>
            <a
              href="/"
              className="sm:text-base text-sm text-indigo-600 font-medium inline-block"
            >
              Add more option
            </a>
          </form>
        </div>
        <div className="md:p-8 sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl sm:mb-6 mb-4">
          <form action="#" method="GET">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Question type
              </label>
              <div className="mt-2">
                <select
                  name=""
                  id=""
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                >
                  <option value="">Multiple choice question</option>
                  <option value="">Multiple choice question</option>
                  <option value="">Multiple choice question</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Question
              </label>
              <div className="mt-2 flex items-center">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                />
                <svg
                  className="sm:ml-4 ml-3 sm:w-8 w-6 sm:h-8 h-6"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0003 29.3337H20.0003C26.667 29.3337 29.3337 26.667 29.3337 20.0003V12.0003C29.3337 5.33366 26.667 2.66699 20.0003 2.66699H12.0003C5.33366 2.66699 2.66699 5.33366 2.66699 12.0003V20.0003C2.66699 26.667 5.33366 29.3337 12.0003 29.3337Z"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9997 13.3333C13.4725 13.3333 14.6663 12.1394 14.6663 10.6667C14.6663 9.19391 13.4725 8 11.9997 8C10.5269 8 9.33301 9.19391 9.33301 10.6667C9.33301 12.1394 10.5269 13.3333 11.9997 13.3333Z"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.55957 25.2671L10.1329 20.8537C11.1862 20.1471 12.7062 20.2271 13.6528 21.0404L14.0928 21.4271C15.1328 22.3204 16.8128 22.3204 17.8528 21.4271L23.3995 16.6671C24.4395 15.7737 26.1195 15.7737 27.1595 16.6671L29.3328 18.5337"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Answer
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="md:p-8 sm:p-6 p-4 bg-white sm:rounded-2xl rounded-xl sm:mb-6 mb-4">
          <form action="#" method="GET">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Question type
              </label>
              <div className="mt-2">
                <select
                  name=""
                  id=""
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                >
                  <option value="">Multiple choice question</option>
                  <option value="">Multiple choice question</option>
                  <option value="">Multiple choice question</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Question
              </label>
              <div className="mt-2 flex items-center">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                />
                <svg
                  className="sm:ml-4 ml-3 sm:w-8 w-6 sm:h-8 h-6"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0003 29.3337H20.0003C26.667 29.3337 29.3337 26.667 29.3337 20.0003V12.0003C29.3337 5.33366 26.667 2.66699 20.0003 2.66699H12.0003C5.33366 2.66699 2.66699 5.33366 2.66699 12.0003V20.0003C2.66699 26.667 5.33366 29.3337 12.0003 29.3337Z"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9997 13.3333C13.4725 13.3333 14.6663 12.1394 14.6663 10.6667C14.6663 9.19391 13.4725 8 11.9997 8C10.5269 8 9.33301 9.19391 9.33301 10.6667C9.33301 12.1394 10.5269 13.3333 11.9997 13.3333Z"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.55957 25.2671L10.1329 20.8537C11.1862 20.1471 12.7062 20.2271 13.6528 21.0404L14.0928 21.4271C15.1328 22.3204 16.8128 22.3204 17.8528 21.4271L23.3995 16.6671C24.4395 15.7737 26.1195 15.7737 27.1595 16.6671L29.3328 18.5337"
                    stroke="#424349"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="country"
                className="block sm:text-base text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Answer
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  className="sm:text-base text-sm block w-full py-1.5 text-gray-900 shadow-sm h-14 border-0 bg-white-gray ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-yellow-300 sm:text-sm sm:leading-6 sm:px-4 px-3  rounded-lg"
                  placeholder="Type here"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="text-center">
          <a
            href="#"
            className="sm:text-base text-sm text-indigo-600 font-medium inline-flex items-center"
          >
            <svg
              className="md:w-10 sm:w-8 w-6 md:h-10 sm:h-8 h-6 sm:mr-4 mr-3"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 10L20 30"
                stroke="#BDBEC5"
                strokeWidth="1.875"
                strokeLinecap="round"
              />
              <path
                d="M30 20L10 20"
                stroke="#BDBEC5"
                strokeWidth="1.875"
                strokeLinecap="round"
              />
              <rect
                x="0.9375"
                y="0.9375"
                width="38.125"
                height="38.125"
                rx="19.0625"
                stroke="#BDBEC5"
                strokeWidth="1.875"
                strokeDasharray="3.75 3.75"
              />
            </svg>
            Add another question
          </a>
        </div>
        <div className="mt-10 text-end">
          <div
            onClick={prevStep}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-shade-gray font-medium sm:text-base text-sm"
          >
            Previous
          </div>
          <div
            onClick={() => {}}
            className="inline-block md:py-4 py-3 md:px-10 px-6 rounded-lg bg-amber-300 font-medium sm:text-base text-sm ml-6"
          >
            Next
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepTwo;
