import icon1 from '../../assets/solution-icon/icon-01.svg';
import icon2 from '../../assets/solution-icon/icon-01.svg';
const Solutions = () => {

    return (
        <section className="bg-[#141414] py-[60px]" id='ourSolution'>
            <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                <div className="flex items-center justify-between lg:mb-[43px] md:mb-[30px] mb-[20px]">
                    <h3 className="lg:text-[25px] md:text-[20px] text-[16px] font-semibold text-white">Our Solutions</h3>
                </div>
            </div>
            <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                <div className="flex flex-wrap items-center">
                    <div className="lg:w-5/12">
                        <div className="lg:p-[57px] md:p-[40px] p-[30px] lg:pl-0">
                            <img className="lg:h-[56px] md:h-[40px] h-[30px] lg:mb-[36px] md:mb-[20px] mb-[10px]" src={icon1} alt="" />
                            <h4 className="font-semibold text-white lg:text-[28px] md:text-[20px] text-[16px] mb-3">Right Candidature Discovery</h4>
                            {/* <p className=" text-[#98989A] text-xs">At Third Bracket we ensure that the responsibilities, tasks, and expectations associated with a particular role within an organization are clearly defined and appropriately matched to the skills, abilities, and interests of the individual filling that role. </p> */}
                        </div>
                    </div>
                    <div className="lg:w-7/12">
                        <div className="lg:border-l border-t lg:border-t-0 border-[#262626] lg:p-[57px] md:p-[40px] sm:p-[30px] p-[10px] lg:pr-0">
                            <div className="flex flex-wrap">
                                <div className="sm:w-6/12 p-2.5">
                                    <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                        <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Advanced Skill Assessments</h4>
                                        <p className="text-[#98989A] text-xs">Utilize state-of-the-art testing and evaluation tools to accurately measure candidates' technical and soft skills.</p>
                                    </div>
                                </div>
                                <div className="sm:w-6/12 p-2.5">
                                    <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                        <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">AI-Driven Matching</h4>
                                        <p className="text-[#98989A] text-xs">Leverage artificial intelligence to match candidates' profiles with job requirements, ensuring a precise fit for each role.</p>
                                    </div>
                                </div>
                                <div className="sm:w-6/12 p-2.5">
                                    <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                        <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Comprehensive Candidate Profiles</h4>
                                        <p className="text-[#98989A] text-xs">Create detailed profiles that include education, experience, certifications, and skillsets for a holistic view of each candidate.</p>
                                    </div>
                                </div>
                                <div className="sm:w-6/12 p-2.5">
                                    <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                        <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Real-Time Candidate Tracking</h4>
                                        <p className="text-[#98989A] text-xs">Implement real-time tracking systems to monitor candidate status and progress throughout the hiring process, ensuring timely and efficient communication.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-t border-[#262626]">
                <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                    <div className="flex flex-wrap items-center">
                        <div className="lg:w-5/12">
                            <div className="lg:p-[57px] md:p-[40px] p-[30px] lg:pl-0">
                                <img className="h-[56px] mb-[36px]" src={icon2} alt="" />
                                <h4 className="font-semibold text-white lg:text-[28px] md:text-[20px] text-[16px] mb-3">Right Cultural Fitment</h4>
                                {/* <p className=" text-[#98989A] text-xs">At Third Bracket we analyze that the values, beliefs, behaviour, and practices of an organization are in harmony with those of its existing and prospective employees. </p> */}
                            </div>
                        </div>
                        <div className="lg:w-7/12">
                            <div className="lg:border-l border-t lg:border-t-0 border-[#262626] lg:p-[57px] md:p-[40px] sm:p-[30px] p-[10px] lg:pr-0">
                                <div className="flex flex-wrap">
                                    <div className="sm:w-6/12 p-2.5">
                                        <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                            <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Cultural Fit Assessments</h4>
                                            <p className="text-[#98989A] text-xs">Use specialised assessments to evaluate candidates' alignment with your company's core values and culture.</p>
                                        </div>
                                    </div>
                                    <div className="sm:w-6/12 p-2.5">
                                        <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                            <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Team Compatibility Analysis</h4>
                                            <p className="text-[#98989A] text-xs">Analyze potential candidates' compatibility with existing team members to foster a harmonious working environment.</p>
                                        </div>
                                    </div>
                                    <div className="sm:w-6/12 p-2.5">
                                        <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                            <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Value Alignment Checks</h4>
                                            <p className="text-[#98989A] text-xs">Ensure that candidates' personal values and ethics align with your organization's mission and vision.</p>
                                        </div>
                                    </div>
                                    <div className="sm:w-6/12 p-2.5">
                                        <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                            <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Comprehensive visualization</h4>
                                            <p className="text-[#98989A] text-xs">A comprehensive candidate profiling is developed that places them in a 2X2 matrix for role alignment and cultural fitment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-t border-[#262626]">
                <div className="xl:max-w-screen-xl lg:max-w-screen-lg md:max-w-screen-md w-full mx-auto px-4">
                    <div className="flex flex-wrap items-center">
                        <div className="lg:w-5/12">
                            <div className="lg:p-[57px] md:p-[40px] p-[30px] lg:pl-0">
                                <img className="h-[56px] mb-[36px]" src={icon2} alt="" />
                                <h4 className="font-semibold text-white lg:text-[28px] md:text-[20px] text-[16px] mb-3">Right Career Goals</h4>
                                {/* <p className=" text-[#98989A] text-xs">At Third Bracket we analyze that the values, beliefs, behaviour, and practices of an organization are in harmony with those of its existing and prospective employees. </p> */}
                            </div>
                        </div>
                        <div className="lg:w-7/12">
                            <div className="lg:border-l border-t lg:border-t-0 border-[#262626] lg:p-[57px] md:p-[40px] sm:p-[30px] p-[10px] lg:pr-0">
                                <div className="flex flex-wrap">
                                    <div className="sm:w-6/12 p-2.5">
                                        <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                            <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Career Aspirations Mapping</h4>
                                            <p className="text-[#98989A] text-xs">Understand and document candidates' long-term career goals and aspirations to align them with available opportunities.</p>
                                        </div>
                                    </div>
                                    <div className="sm:w-6/12 p-2.5">
                                        <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                            <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Growth Potential Analysis</h4>
                                            <p className="text-[#98989A] text-xs">Assess candidates' potential for growth and advancement within your organization based on their skills and career goals.</p>
                                        </div>
                                    </div>
                                    <div className="sm:w-6/12 p-2.5">
                                        <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                            <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Development Pathways</h4>
                                            <p className="text-[#98989A] text-xs">Create clear development pathways and professional growth plans tailored to each candidate's career objectives.</p>
                                        </div>
                                    </div>
                                    <div className="sm:w-6/12 p-2.5">
                                        <div className="md:p-[28px] sm:p-[20px] p-[10px] bg-[#1A1A1A] flex flex-col gap-[14px] rounded-lg">
                                            <h4 className="lg:text-lg md:text-base text-sm font-semibold text-white">Mentorship Programs</h4>
                                            <p className="text-[#98989A] text-xs">Establish mentorship programs to support employees' career development and help them achieve their professional goals.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Solutions;