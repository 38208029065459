import React from "react";

type Props = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  text: string;
  iconLocation?: any;
};
const ButtonOne = ({ onClick, text, iconLocation, className }: Props) => {
  return (
    <div
      className={
        "cursor-pointer inline-flex items-center border-b w-full border-yellow-300 leading-9 lg:text-base text-sm  text-gray-black bg-amber-300 justify-center rounded-lg sm:py-4 py-2 sm:text-base mb-4 " +
        className
      }
      onClick={onClick}
    >
      {iconLocation && (
        <img className="sm:w-6 sm:h-6 w-4 h-4 mr-3" src={iconLocation} alt="" />
      )}
      {text}
    </div>
  );
};

export default ButtonOne;
