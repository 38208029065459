import React, { useState } from 'react';
import arrowLeft from '../../../assets/arrow-left-long.svg';
import p1 from '../../../assets/profile-icon/1.svg';
import p2 from '../../../assets/profile-icon/2.svg';
import p3 from '../../../assets/profile-icon/3.svg';
import p4 from '../../../assets/profile-icon/4.svg';
import { axiosPrivate, axiosPublic } from '../../../api/axios';
import JobsRecommendations from './Recommendations/Jobs';
import RecommendedCourses from './Recommendations/Courses';
import ButtonOne from '../../shared/ButtonOne';
import { toast } from 'react-toastify';
import { Chart as ChartJS, registerables } from 'chart.js';

import { Bar, Pie } from 'react-chartjs-2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(...registerables);

const StepSixContent = ({ id }: any) => {
  const [results, setResults] = useState<any>({});
  const [recommendations, setRecommendations] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [chartData, setChartData] = useState<any>([]);
  const [chartDatascoresCopentencyType, setChartDatascoresCopentencyType] = useState<any>([]);
  const [chartDataCapability, setchartDataCapability] = useState<any>([]);

  React.useEffect(() => {
    (async () => {
      await axiosPublic.get(`/assessment/${id}/results`).then((res) => {
        setResults(res.data);
      });
    })();
  }, [id]);

  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 0.7)`; // Using RGBA for semi-transparency
  };

  React.useEffect(() => {
    if (results?._id) {
      (async () => {
        await axiosPublic
          .get(`/assessment/${results?._id}/recommendations`)
          .then((res) => {
            setRecommendations(res?.data);
          });
      })();

      if (results.scores) {
        // console.log(results.scoresCopentencyType);
        const pieChartData = Object.keys(results.scores).map((key) => ({
          label: key,
          value: results.scores[key].total_percentage,
          backgroundColor: getRandomColor(),
        }));

        const pieChartDataScoresCopentencyType = Object.keys(results.scoresCopentencyType).map((key) => ({
          label: key,
          value: results.scoresCopentencyType[key].total_percentage,
          backgroundColor: getRandomColor(),
        }));

        const piechartDataCapability = Object.keys(results.finalScoresCababilyty).map((key) => ({
          label: key,
          value: results.finalScoresCababilyty[key].total_percentage,
          backgroundColor: getRandomColor(),
        }));

        setChartData(pieChartData);
        setChartDatascoresCopentencyType(pieChartDataScoresCopentencyType);
        setchartDataCapability(piechartDataCapability);
      }
    }
  }, [results]);

  const downloadResult = async () => {
    setLoading(true);
    await axiosPrivate
      .get(`/generate-scores-pdf/${id}`)
      .then((res) => {
        setLoading(false);
        window.location = res.data;
      })
      .catch((err) => {
        setLoading(false);
        toast.error('There was an error downloading your result');
      });
  };
  const downloadAnswer = async () => {
    setLoading(true);
    await axiosPrivate
      .get(`/generate-answer-pdf/${id}`)
      .then((res) => {
        setLoading(false);
        window.location = res.data;
      })
      .catch((err) => {
        setLoading(false);
        toast.error('There was an error downloading your result');
      });
  };

  const downloadCertificate = async () => {
    setLoading(true);
    await axiosPrivate
      .get(`/certificate/${id}`)
      .then((res) => {
        setLoading(false);
        window.location = res.data;
      })
      .catch((err) => {
        setLoading(false);
        toast.error('There was an error downloading your certificate');
      });
  };

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        max: 100
      }
    }
  };
  let screType = results?.totalPercentage?.toFixed(2) > 80
    ? "Excellent"
    : results?.totalPercentage?.toFixed(2) > 70
      ? "Best"
      : results?.totalPercentage?.toFixed(2) > 60
        ? "Better"
        : results?.totalPercentage?.toFixed(2) > 50
          ? "Good"
          : results?.totalPercentage?.toFixed(2) > 40
            ? "Average"
            : results?.totalPercentage?.toFixed(2) > 30
              ? "Below Average"
              : "Below Average";


  const [url, setUrl] = useState(
    `${window.location.protocol}//${window.location.host}/certificate?resultId=${id}`
  );

  const [copied, setCopied] = useState(false);

  React.useEffect(() => {
    if (copied) {
      // Set a timer to reset the 'copied' state to false after 5 seconds
      const timer = setTimeout(() => setCopied(false), 5000);

      // Clear the timeout if the component is unmounted or if copied changes
      return () => clearTimeout(timer);
    }
  }, [copied]);
  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-400 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Generating...
          </h2>
          <p className="w-1/3 text-center text-white">
            Please wait a while, we are generating your result.
          </p>
        </div>
      )}
      <section className="md:py-7 py-5 bg-light-white">
        <div className="mx-auto max-w-7xl md:pt-7 pt-5 px-4">
          <div className="flex flex-wrap items-center  md:mb-8 sm:mb-6 mb-4">
            <div className="md:w-1/5 w-full md:mb-0 mb-3">
              <a
                href="/dashboard"
                className="sm:py-4 py-2 sm:px-4 px-3 bg-dark-gray rounded-lg inline-flex items-center font-medium sm:text-base text-sm"
              >
                <img
                  className="sm:mr-2 mr-1 sm:w-5 w-4"
                  src={arrowLeft}
                  alt=""
                />{' '}
                Back
              </a>
            </div>
            <div className="md:w-4/5 w-full">
              <div className="flex flex-wrap sm:p-6 p-4 rounded-2xl bg-white">
                <div className="flex lg:w-1/4 sm:w-1/2 w-full lg:mt-0 mt-4 items-center pr-4">
                  <span className="sm:h-20 h-16 sm:w-20 w-16 bg-blue-50 inline-flex justify-center items-center mr-3 rounded-xl">
                    <img src={p1} alt="" />
                  </span>
                  <div>
                    <p className="sm:text-sm text-xs text-dark-black mb-1">
                      Rank
                    </p>
                    {/* <h4 className="sm:text-base text-sm font-semibold">
                      1 / 15K
                    </h4> */}
                  </div>
                </div>
                <div className="flex lg:w-1/4 sm:w-1/2 w-full lg:mt-0 mt-4 items-center pr-4">
                  <span className="sm:h-20 h-16 sm:w-20 w-16 bg-amber-50 inline-flex justify-center items-center mr-3 rounded-xl">
                    <img src={p2} alt="" />
                  </span>
                  <div>
                    <p className="sm:text-sm text-xs text-dark-black mb-1">
                      {results?.toalTimeTaken}
                    </p>
                    {/* <h4 className="sm:text-base text-sm font-semibold">
                      10:00
                    </h4> */}
                  </div>
                </div>
                <div className="flex lg:w-1/4 sm:w-1/2 w-full lg:mt-0 mt-4 items-center pr-4">
                  <span className="sm:h-20 h-16 sm:w-20 w-16 bg-green-50 inline-flex justify-center items-center mr-3 rounded-xl">
                    <img src={p3} alt="" />
                  </span>
                  <div>
                    <p className="sm:text-sm text-xs text-dark-black mb-1">
                      {results?.assessmentDetails?.jobRole?.title}
                    </p>
                    {/* <h4 className="sm:text-base text-sm font-semibold">90%</h4> */}
                  </div>
                </div>
                <div className="flex lg:w-1/4 sm:w-1/2 w-full lg:mt-0 mt-4 items-center">
                  <span className="sm:h-20 h-16 sm:w-20 w-16 bg-purple-50 inline-flex justify-center items-center mr-3 rounded-xl">
                    <img src={p4} alt="" />
                  </span>
                  <div>
                    <p className="sm:text-sm text-xs text-dark-black mb-1">
                      Assessment Completed
                    </p>
                    {/* <h4 className="sm:text-base text-sm font-semibold">500</h4> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3 className="md:text-3xl sm:text-2xl text-xl md:mb-6 sm:mb-4 mb-2 font-medium flex items-center justify-between">
            <div> Performance Analysis ({results?.totalPercentage?.toFixed(2)}% Total)  ({screType})</div>
            <div className="mx-2">
              <ButtonOne
                text="Download Detailed Assessment Report"
                onClick={downloadResult}
                className="p-2"
              />
            </div>
            <div className="mx-2">
              <ButtonOne
                text="Download Certificate"
                onClick={downloadCertificate}
                className="p-2"
              />
            </div>
            <div className="mx-2">
              <ButtonOne
                text="Download Answer"
                onClick={downloadAnswer}
                className="p-2"
              />
            </div>
          </h3>
          <h3 className="md:text-3xl sm:text-2xl text-xl md:mb-6 sm:mb-4 mb-2 font-medium flex items-center justify-between">
            <div>
              Certificate Link ({url})
              <div style={{ paddingLeft: "1rem" }}>
                <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
                  <span style={{ cursor: 'pointer', color: 'blue' }}><FontAwesomeIcon icon={faCopy} style={{ marginRight: '5px' }} /></span>
                </CopyToClipboard>
                {copied && <span style={{ marginLeft: '10px', color: 'green' }}>Copied!</span>}
              </div>
            </div>
          </h3>
          <div className="flex flex-wrap justify-between -mx-3">
            <div className="lg:w-6/12 w-full px-3">
              <div className="md:p-8 py-6 px-4 md:rounded-2xl rounded-xl bg-white md:mb-6 mb-4">
                {Object.entries(results?.scores || {}).map(
                  ([compId, compData]: [string, any], idx: number) => {
                    return (
                      <div key={idx}>
                        <div className="sm:mb-6 mb-4">
                          <div className="flex items-center justify-between sm:mb-4 mb-2">
                            <div className="mb-1 text-base font-medium">
                              {compId}
                            </div>
                            <div className="mb-1 text-base font-medium ">
                              {compData?.total_percentage?.toFixed(2)}%
                            </div>
                          </div>
                          <div className="w-full bg-gray-300 rounded-full h-2 mb-4 dark:bg-gray-700">
                            <div
                              className="bg-amber-300 h-2 rounded-full"
                              style={{ width: `${compData.total_percentage}%` }}
                            />
                          </div>
                        </div>
                        {compData.capabilities.map(
                          (capability: any, capIdx: number) => {
                            return (
                              <div
                                className="sm:mb-6 mb-4 ml-4 bg-gray-200 p-2 rounded-lg"
                                key={capIdx}
                              >
                                <div className="flex items-center justify-between sm:mb-4 mb-2">
                                  <div className="mb-1 text-base font-medium">
                                    {capability.title /* Capability title */}
                                  </div>
                                  <div className="mb-1 text-base font-medium ">
                                    {capability?.percentage?.toFixed(2) || 0}%
                                  </div>
                                </div>
                                <div className="w-full bg-gray-300 rounded-full h-2 mb-4 dark:bg-gray-700">
                                  <div
                                    className="bg-amber-300 h-2 rounded-full"
                                    style={{
                                      width: `${capability?.percentage || 0}%`,
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="lg:w-6/12 w-full px-3">
              <div className="md:p-8 py-6 px-4 md:rounded-2xl rounded-xl bg-white md:mb-6 mb-4">
                <Bar width={"100%"} height={"50%"} data={{
                  labels: chartData.map((data: any) => data.label),
                  datasets: [
                    {
                      data: chartData.map((data: any) => {
                        return data.value
                      }),
                      backgroundColor: chartData.map(
                        (data: any) => data.backgroundColor
                      ),
                    },
                  ],
                }} options={options} />
                <br />
                <br />
                <br />
                <Bar width={"100%"} height={"50%"} data={{
                  labels: chartDatascoresCopentencyType.map((data: any) => data.label),
                  datasets: [
                    {
                      data: chartDatascoresCopentencyType.map((data: any) => {
                        return data.value
                      }),
                      backgroundColor: chartDatascoresCopentencyType.map(
                        (data: any) => data.backgroundColor
                      ),
                    },
                  ],
                }} options={options} />
                <br />
                <br />
                <br />
                <Bar width={"100%"} height={"50%"} data={{
                  labels: chartDataCapability.map((data: any) => data.label),
                  datasets: [
                    {
                      data: chartDataCapability.map((data: any) => {
                        return data.value
                      }),
                      backgroundColor: chartDataCapability.map(
                        (data: any) => data.backgroundColor
                      ),
                    },
                  ],
                }} options={options} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {recommendations?.matchingJobs?.length > 0 && (
        <JobsRecommendations jobs={recommendations?.matchingJobs} />
      )}
      {recommendations?.matchingCourses?.length > 0 && (
        <RecommendedCourses courses={recommendations?.matchingCourses} />
      )}
    </>
  );
};

export default StepSixContent;
