import React from 'react';
import pb from '../../../assets/participants-black.svg';
import setup from '../../../assets/setup-img.png';
import arrowLeft from '../../../assets/arrow-left-long.svg';

const StepTwoContent = ({
  prevStep,
  setAcceptTerms,
  enableFullScreen,
  getUserLocation,
}: any) => {
  React.useEffect(() => {
    getUserLocation();
  }, []);
  return (
    <section className="md:py-7 py-5 bg-light-white min-h-[calc(100vh-152px)]">
      <div className="mx-auto max-w-7xl px-4">
        <div
          onClick={prevStep}
          className="sm:py-4 py-2 sm:px-4 px-3 bg-dark-gray rounded-lg inline-flex items-center font-medium sm:text-base text-sm md:mb-8 sm:mb-6 mb-4"
        >
          <img className="sm:mr-2 mr-1 sm:w-5 w-4" src={arrowLeft} alt="" />{' '}
          Back
        </div>
        <h3 className="md:text-3xl sm:text-2xl text-xl md:mb-6 sm:mb-4 mb-2 font-medium">
          Instructions
        </h3>
        <div className="flex flex-wrap justify-between">
          <div className="md:w-8/12 w-full">
            <p className="text-red-600">
              *Scroll down to accept Terms & Conditions
            </p>
            <p className="text-red-600">
              <strong>Important NOTE:</strong>
            </p>
            <strong>
              BY CONTINUING TO TEST YOU WILL BE SHIFTED TO FULL SCREEN MODE.
              PLEASE DON'T EXIT THE FULL SCREEN AS IT WILL CANCEL YOUR
              ASSESSMENT.
            </strong>{' '}
            <br />
            Instructions – When taking a multiple-choice assessment, it's
            essential to approach it strategically to maximize your chances of
            success. Here are some instructions to follow: <br /> 1. Read each
            question carefully: Take your time to read each question thoroughly
            and understand what is being asked. Pay attention to keywords or
            phrases that might indicate the correct answer. <br /> 2. Analyse
            all choices: Read all the options before selecting an answer.
            Sometimes, the first option may seem correct, but another one could
            be more accurate. Don't rush to choose the first seemingly correct
            answer you see. <br /> 3. Eliminate wrong answers: If you are unsure
            about a question, try to eliminate obviously incorrect options.
            Narrowing down the choices increases your odds of selecting the
            right answer.
            <br /> 4. Manage your time: Keep track of the time you spend on each
            question. If you get stuck on a difficult one, move on and return to
            it later if time permits. Don't spend too much time on a single
            question. <br /> 5. Watch for qualifiers: Pay attention to words
            like "always," "never," "sometimes," "most," or "least." These
            qualifiers can give you clues about the answer's accuracy. <br />
            6. Be cautious with "All of the above" and "None of the above":
            These options can be tricky. Don't automatically assume they are
            correct or incorrect. Evaluate each choice independently before
            making a decision. <br /> 7. Stay confident, but don't guess wildly:
            If you don't know the answer, it's better to make an educated guess
            rather than leaving it blank. However, avoid wild guesses, as
            incorrect answers might lead to penalties in some assessments.{' '}
            <br /> 8. Review your answers: If time allows, go back and review
            your answers. Check for any careless mistakes or misinterpretations.{' '}
            <br /> 9. Answer every question: In most cases, there is no penalty
            for guessing. If you're running out of time, make sure you fill in
            an answer for each question. <br />
            10. If a candidate is found using unfair means his assessment will
            be revoked. <br /> 11. Trust your instincts: If you've thoroughly
            prepared and have a gut feeling about an answer, go with it. Often,
            your intuition can guide you in the right direction. Remember,
            preparation is crucial for success in any assessment. Ensure you
            have studied and revised the relevant material before attempting the
            test. Good luck!
            <div className="md:pt-6 py-4 border-t-2 border-gray-300">
              {/* <div className="flex items-center justify-between w-full md:mb-6 mb-4">
                <div>
                  <h4 className="flex items-center sm:text-base text-sm font-semibold mb-1">
                    <img
                      className="sm:h-6 sm:w-6 h-4 w-4 mr-2"
                      src={pb}
                      alt=""
                    />{' '}
                    Participate
                  </h4>
                  <p className="md:text-base text-sm text-dark-black">
                    FaceTime HD Camera
                  </p>
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="md:h-12 h-8 md:w-12 w-8 rounded-lg setup-checkbox"
                  />
                </div>
              </div> */}
              <div className="flex items-center justify-between w-full">
                <div>
                  {/* <h4 className="flex items-center sm:text-base text-sm  font-semibold mb-1">
                    <img className="h-6 w-6 mr-2" src={pb} alt="" /> Microphone
                  </h4> */}
                  <p className="md:text-base text-sm text-dark-black">
                    I ACCEPT THE TERMS & CONDITIONS.
                  </p>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    className="md:h-12 h-8 md:w-12 w-8 rounded-lg setup-checkbox"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-5/12 w-full">
            {/* <div>
              <img className="w-full" src={setup} alt="" />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepTwoContent;
